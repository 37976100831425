import axios from 'axios';
import { setAlert } from './alert';

import {
  ACCOUNT_DELETED,
  CLEAR_PROFILE,
  GET_PROFILE,
  PROFILE_ERROR,
  TAKE_ATTENDANCE,
  ATTENDANCE_ERROR,
  UPDATE_PROFILE,
  GET_PROFILES,
} from './types';

// Get current users profile
export const getCurrentProfile = () => async (dispatch) => {
  try {
    const res = await axios.get('https://deacon-service-backend.onrender.com/api/profile/me');

    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get all profiles
export const getProfiles = () => async (dispatch) => {
  try {
    const res = await axios.get('https://deacon-service-backend.onrender.com/api/profile');
    dispatch({
      type: GET_PROFILES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Create or update profile
export const createProfile =
  (formData, navigate, edit = false) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const res = await axios.post('https://deacon-service-backend.onrender.com/api/profile', formData, config);

      dispatch({
        type: GET_PROFILE,
        payload: res.data,
      });

      dispatch(setAlert(edit ? 'Profile Updated' : 'Profile Created', 'success'));

      if (!edit) {
        navigate('/schedule');
      }
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }

      dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };

// Set Profile As Absent
export const setAbsent = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(`https://deacon-service-backend.onrender.com/api/profile/absent/${userId}`);
    dispatch({
      type: TAKE_ATTENDANCE,
      payload: res.data,
    });
    dispatch(setAlert('Status Updated To Absent', 'success'));
  } catch (err) {
    dispatch({
      type: ATTENDANCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Set Profile As Present
export const setPresent = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(`https://deacon-service-backend.onrender.com/api/profile/present/${userId}`);
    dispatch({
      type: TAKE_ATTENDANCE,
      payload: res.data,
    });
    dispatch(setAlert('Status Updated To Present', 'success'));
  } catch (err) {
    dispatch({
      type: ATTENDANCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Set Profile As Present
export const setReset = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(`https://deacon-service-backend.onrender.com/api/profile/reset/${userId}`);
    dispatch({
      type: TAKE_ATTENDANCE,
      payload: res.data,
    });
    dispatch(setAlert('Status Reseted', 'success'));
  } catch (err) {
    dispatch({
      type: ATTENDANCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
