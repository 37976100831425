import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, Typography, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
// components
import Iconify from '../../../components/iconify';

// React
import { Link, Navigate } from 'react-router-dom';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { loadUser, register } from 'src/actions/auth';
import { createProfile } from 'src/actions/profile';
import { setAlert } from 'src/actions/alert';
import { getSchedule } from 'src/actions/schedule';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Gives us the state of isAuthenticated

  const [formData, setFormData] = useState({
    name: '',
    familyName: '',
    email: '',
    password: '',
    password2: '',
  });

  const [profileData, setProfileData] = useState({
    phone: '',
    title: '',
  });

  //Destructure
  const { name, familyName, email, password, password2 } = formData;

  const { phone, title } = profileData;

  const handleChange = (e) => {
    if (e.target.name == 'phone') {
      setProfileData({ ...profileData, phone: formatPhoneNumber(e.target.value) });
    } else {
      setProfileData({ ...profileData, [e.target.name]: e.target.value });
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (password !== password2) {
      dispatch(setAlert('Passwords do not match', 'error'));
    } else if (
      name == '' ||
      familyName == '' ||
      email == '' ||
      password == '' ||
      password2 == '' ||
      phone == '' ||
      title == '' ||
      phone.length != 14
    ) {
      dispatch(setAlert('Please fill in all the fields properly.', 'error'));
    } else {
      dispatch(register({ name, familyName, email, password }));
    }
  };

  let formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    } else {
      return str;
    }
  };

  if (isAuthenticated) {
    dispatch(createProfile(profileData, navigate));
    dispatch(getSchedule());
    // return <Navigate to="/schedule" />;
  }

  return (
    <>
      <Stack spacing={3}>
        <TextField name="name" label="Name" value={name} onChange={(e) => onChange(e)} />
        <TextField name="familyName" label="Family Name" value={familyName} onChange={(e) => onChange(e)} />
        <TextField type="email" name="email" label="Email address" value={email} onChange={(e) => onChange(e)} />
        <TextField
          type="text"
          name="phone"
          inputProps={{ maxLength: 14, minLength: 14 }}
          label="Phone Number"
          value={phone}
          onChange={(e) => handleChange(e)}
        />

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Title</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="title"
            value={title}
            label="Title"
            onChange={(e) => handleChange(e)}
          >
            <MenuItem value={'Responsable'}>
              <b>Responsable </b>
              <i>- Must know all the movements to be done in the altar and all the responses</i>
            </MenuItem>
            <MenuItem value={'Helper'}>
              <b>Helper</b>
              <i>- Cannot serve alone and need to be guided in the altar but knows what to do and when to answer</i>
            </MenuItem>
            <MenuItem value={'Kid'}>
              <b>Kid</b>
              <i>- I am less than 12 years old </i>
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => onChange(e)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          name="password2"
          label="Confirm Password"
          type={showPassword ? 'text' : 'password'}
          value={password2}
          onChange={(e) => onChange(e)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography variant="body2" sx={{ mb: 5 }}>
          Already have an account? {''}
          <Link to="/login">Sign In</Link>
        </Typography>
        <Typography variant="body2" sx={{ mb: 5, paddingBottom: 2 }}>
          {/* Forgot your passwordt? {''} */}
          {/* <Link to='/reset'>Reset Password</Link> */}
        </Typography>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={(e) => onSubmit(e)}>
        Sign Up
      </LoadingButton>
    </>
  );
}
