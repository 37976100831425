import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import AlertComponent from './components/Alert/Alert';

//Redux
import { Provider, useSelector } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import { useEffect } from 'react';
import setAuthToken from './utils/setAuthToken';
import { getSchedule } from './actions/schedule';

// ----------------------------------------------------------------------

export default function App() {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  //Want to load the user
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <Provider store={store}>
            <ScrollToTop />
            <StyledChart />
            <Router />
          </Provider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
