import { GET_SCHEDULE, UPDATE_SCHEDULE, SCHEDULE_ERROR, CLEAR_SCHEDULE, REMOVE_FROM_SCHEDULE } from '../actions/types';

const initialState = {
  schdedule: null,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SCHEDULE:
      return {
        ...state,
        schedule: payload,
        loading: false,
      };
    case UPDATE_SCHEDULE:
    case REMOVE_FROM_SCHEDULE:
      return {
        ...state,
        schedule: payload,
        loading: true,
      };
    case CLEAR_SCHEDULE:
      return {
        ...state,
        schedule: null,
        loading: false,
      };
    case SCHEDULE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        schedule: null,
      };

    default:
      return state;
  }
}
