// component
import SvgColor from '../../../components/svg-color';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FactCheckIcon from '@mui/icons-material/FactCheck';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Schedule',
    path: '/schedule',
    icon: <CalendarMonthIcon />,
  },
  {
    title: 'profile',
    path: '/profile',
    icon: <AccountBoxIcon />,
  },
  {
    title: 'attendance',
    path: '/attendance',
    icon: <FactCheckIcon />,
  },
];

export default navConfig;
