import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, Typography, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
// components
import Iconify from '../../../components/iconify';

// React
import { Link, Navigate } from 'react-router-dom';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { register } from 'src/actions/auth';
import { createProfile, getCurrentProfile } from 'src/actions/profile';

// ----------------------------------------------------------------------

export default function UpdateForm() {
  const profile = useSelector((state) => state.profile.profile);
  const loading = useSelector((state) => state.profile.loading);

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Gives us the state of isAuthenticated

  const [formData, setFormData] = useState({
    phone: '',
    title: '',
    availability: '',
  });

  //Destructure
  const { phone, title, availability } = formData;

  useEffect(() => {
    dispatch(getCurrentProfile());
    setFormData({
      // Set it in the beginning from the profile
      phone: loading || !profile.phone ? '' : profile.phone,
      title: loading || !profile.title ? '' : profile.title,
      availability: loading || !profile.availability ? '' : profile.availability,
    });
  }, [loading, getCurrentProfile]);

  const onChange = (e) => {
    if (e.target.name == 'phone') {
      setFormData({ ...formData, phone: formatPhoneNumber(e.target.value) });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // Update Profile Here
    dispatch(createProfile(formData, navigate, true));
  };

  let formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }

    return null;
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField type="text" name="phone" label="Phone Number" value={phone} onChange={(e) => onChange(e)} />

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Title</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="title"
            value={title}
            label="Title"
            onChange={(e) => onChange(e)}
          >
            <MenuItem value={'Responsable'}>
              <b>Responsable </b>
              <i>- Must know all the movements to be done in the altar and all the responses</i>
            </MenuItem>
            <MenuItem value={'Helper'}>
              <b>Helper</b>
              <i>- Cannot serve alone and need to be guided in the altar but knows what to do and when to answer</i>
            </MenuItem>
            <MenuItem value={'Kid'}>
              <b>Kid</b>
              <i>- I am less than 12 years old </i>
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Available</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="availability"
            value={availability}
            label="Availability"
            onChange={(e) => onChange(e)}
          >
            <MenuItem value={'true'}>Yes</MenuItem>
            <MenuItem value={'false'}>No</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="body2" sx={{ mb: 5, paddingBottom: 2 }}>
          {/* Forgot your passwordt? {''} */}
          {/* <Link to='/reset'>Reset Password</Link> */}
        </Typography>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={(e) => onSubmit(e)}>
        Update Profile
      </LoadingButton>
    </>
  );
}
