import { Helmet } from 'react-helmet-async';
import AlertComponent from 'src/components/Alert/Alert';
import '../app.css';
import UpdateForm from 'src/sections/auth/login/UpdateForm';

// ----------------------------------------------------------------------

export default function ProfilePage() {
  return (
    <>
      <div className="alert">
        <AlertComponent />
        <h1>Profile</h1>

        <UpdateForm />
      </div>
    </>
  );
}
