import React from 'react';
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';

export default function AlertComponent() {
  const alerts = useSelector((state) => state.alert);

  var badAlert = ' ';

  const alertMessage =
    alerts !== null &&
    alerts.length > 0 &&
    alerts.map((alert) => (
      <Alert className="alert" key={alert.id} severity={badAlert != ' ' ? badAlert : alert.alertType} variant="filled">
        {alert.msg}
      </Alert>
    ));

  return alertMessage;
}
