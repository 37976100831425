import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, Typography, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

// React
import { Link, Navigate } from 'react-router-dom';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { loadUser, login } from 'src/actions/auth';
import { getSchedule } from 'src/actions/schedule';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Gives us the state of isAuthenticated

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  //Destructure
  const { email, password } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value }); // the ...fromData makes a copy of the current state
  // Then the e.target.name changes the field that is called like the name

  const onSubmit = async (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  if (isAuthenticated) {
    dispatch(getSchedule());
    return <Navigate to="/schedule" />;
  }

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" value={email} onChange={(e) => onChange(e)} />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => onChange(e)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography variant="body2" sx={{ mb: 5 }}>
          Don’t have an account? {''}
          <Link to="/register">Get Started</Link>
        </Typography>
        <Typography variant="body2" sx={{ mb: 5, paddingBottom: 2 }}>
          {/* Forgot your passwordt? {''} */}
          {/* <Link to='/reset'>Reset Password</Link> */}
        </Typography>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={(e) => onSubmit(e)}>
        Login
      </LoadingButton>
    </>
  );
}
