import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Navigate } from 'react-router-dom';
import { getCurrentProfile } from 'src/actions/profile';
import { useEffect } from 'react';
import Spinner from '../Spinner/Spinner';

function AdminRoute({ children }) {
  const { profile, loading } = useSelector((state) => state.profile);
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentProfile());
  }, [loading]);

  return loading ? (
    <Spinner />
  ) : profile.title == 'Admin' && auth.isAuthenticated ? (
    children
  ) : (
    <Navigate to="/schedule" />
  );
}

export default AdminRoute;
