import axios from 'axios';
import { setAlert } from './alert';
import { GET_SCHEDULE, UPDATE_SCHEDULE, SCHEDULE_ERROR, REMOVE_FROM_SCHEDULE } from '../actions/types';

// Get schedule

export const getSchedule = () => async (dispatch) => {
  try {
    const res = await axios.get('https://deacon-service-backend.onrender.com/api/schedule');

    dispatch({
      type: GET_SCHEDULE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SCHEDULE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Decline Schedule

export const declineInvitation = () => async (dispatch) => {
  try {
    const res = await axios.get(`https://deacon-service-backend.onrender.com/api/schedule/delete`);

    dispatch({
      type: REMOVE_FROM_SCHEDULE,
      payload: res.data,
    });
    dispatch(setAlert('Status Updated', 'success'));
  } catch (err) {
    dispatch({
      type: SCHEDULE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Change Schedule

export const changeSchedule = () => async (dispatch) => {
  try {
    const res = await axios.get(`https://deacon-service-backend.onrender.com/api/schedule/new`);

    dispatch({
      type: UPDATE_SCHEDULE,
      payload: res.data,
    });
    dispatch(setAlert('Schedule Updated', 'success'));
  } catch (err) {
    dispatch({
      type: SCHEDULE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
